.heading1 {
  color: var(--text);
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: normal;
  margin: 0;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}