.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  min-height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background: var(--gradient1);
  background: linear-gradient(0deg, var(--gradient2) 0%, var(--gradient1) 40%, var(--gradient1) 60%, var(--gradient2) 100%);
}

.modal {
  position: fixed;
  display: flex;
  top: 0;
  left: 0 !important;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.veryTop {
  z-index: 300;
  background: rgb(202, 252, 214);
  background: linear-gradient(0deg, var(--green-light-gradient) 0%, var(--white) 40%, var(--white) 60%, var(--green-light-gradient) 100%);
}
.centre{
  justify-content: center;
  background: #ffffffe3;
  align-items: center;
}

.modalContent {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.centre .modalContent{
  flex-grow: inherit;
  background: rgb(202, 252, 214);
  background: linear-gradient(0deg, var(--green-light-gradient) 0%, var(--white) 40%, var(--white) 60%, var(--green-light-gradient) 100%);
  border-radius: 0 0 40px 40px;
}


.header {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;

}

.headerContainer {
  display: flex;
  color: var(--text);
  padding: 22px 30px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary);
  height: 74px;
  width: 100%;
  box-sizing: border-box;
}

.centre .headerContainer{
  border-radius: 40px 40px 0 0;
}

.headerCenter {
  flex: 1;
  text-align: center;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
}

.headerLeft {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  padding: 10px;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
}



.contentText {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  min-width: 300px;
  text-align: center;
}

.contentTextFull {
  max-width: 100%;
  min-width: 80%;
}




.contentTextTop {
  justify-content: flex-start;
  padding-top: 20px;
}

.contentText h1 {
  color: var(--primaryDark);
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
}
.contentText h2 {
  color: var(--primaryDark);
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
}
.contentText h2 strong{
  /*font-weight: normal;*/
}

.contentText h3 {
  color: var(--black);
  text-align: center;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: inherit;
  font-weight: normal;
}

.contentTextTop,
.contentTextTop h2,
.contentTextTop h3 {
  text-align: left;
}

.contentText p {
  font-weight: normal;
}

.contentText img{
  max-width: 100%;
}

.contentText ul {
  text-align: left;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerContent {
  max-width: 300px;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 15px 20px;
}

.footerContent button {
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
  box-shadow: none;
  padding: 15px 20px;
}


.callToActionContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

.callToActionContainer button{
  max-width: 60%;
}

.shareModalTableHeader{
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  justify-content: space-between;
  width:100%
}
.shareModalTableHeaderCell{
  padding:10px 0;

}

.shareMessage {
  padding: 10px;
  background: none;
  background-color: var(--white);
  border: none;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  width: auto;
  /* flex-grow: 1; */
  height: 80px;
  min-height: 50px;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  border-radius: 10px;
  margin-bottom: 20px;
}
.shareSignupContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cursorPointer {
  cursor: pointer;
}

a,
a span {
  color: var(--primaryDark) !important;
}