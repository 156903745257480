.topMenu {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
}

.topMenuContainer {
  display: flex;
  width: calc(100% - 20px);
  color: var(--text);
  padding: 12px 25px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary);
  height: 74px;
  width: 100%;
  box-sizing: border-box;
}

.topMenuTitle {
  flex: 1;
  text-align: center;
  margin-right: 30px;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 20px;
  padding: 20px 10px;
}

.nameInput {
  color: var(--black300);
  border: none;
  background: none;
  flex: 1;
  text-align: center;
  margin-left: 60px;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: normal;
  text-overflow: ellipsis;
}

.nameInput::placeholder {
  color: var(--black300);
}

.nameInput,
.nameInput::placeholder {
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: normal;
}

.festivalNameButton {

}

.festivalName {
  color: var(--black300);
  flex: 1;
  text-align: center;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: normal;
  text-overflow: ellipsis;
}


.icon {
  margin-right: 20px;
  margin-top: 12px;
  width: 25px;
  /*filter to invert from white icon to black icon*/
  filter: invert(100%) sepia(7%) saturate(157%) hue-rotate(118deg) brightness(116%) contrast(100%);
}


@media (max-width: 768px) {
  .icon {
    margin-right: 0px;
  }

  .nameInput {
    margin-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

}