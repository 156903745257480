.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 199;
  min-height: 100%;
  background: rgb(202, 252, 214);
  background: linear-gradient(0deg, var(--green-light-gradient) 0%, var(--white) 40%, var(--white) 60%, var(--green-light-gradient) 100%);
}



.alert {
  position: fixed;
  display: flex !important;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0 !important;
  z-index: 400;
  width: 100%;
  height: 100%;
}


.alertContent {
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 40px;
  width: 300px;
  max-width: calc(100% - 80px);
  overflow: hidden;
  /*https://html-css-js.com/css/generator/box-shadow/*/
  -webkit-box-shadow: 5px 5px 27px 5px rgba(0, 0, 0, 0.31);
  box-shadow: 5px 5px 27px 5px rgba(0, 0, 0, 0.31);
  padding: 30px;
}

.alertHeader {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
  padding: 10px 20px 10px 20px;
  color: var(--black);
}


.alertText {
  display: flex;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: normal;
  padding: 10px 20px 20px 20px;
  color: var(--black);
  line-height: 18px;
}


.alertFooter {
  max-width: 300px;
  display: flex;
  justify-content: center;
}