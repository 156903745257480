.tabBg {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffffffbb;
  height: 100%;
  width: 100%;
  z-index: 200;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.tabTopSpace {
  flex-grow: 1;
}

.tabContainer {
  border-radius: 0 50px 0 0;
  /*https://html-css-js.com/css/generator/box-shadow/*/
  -webkit-box-shadow: 5px 5px 27px 5px rgba(0, 0, 0, 0.31);
  box-shadow: 5px 5px 27px 5px rgba(0, 0, 0, 0.31);
  width: 100%;
  height: 100%;
  /*background: rgb(202, 252, 214);
  background: linear-gradient(0deg, var(--green-light-gradient) 0%, var(--white) 40%, var(--white) 60%, var(--green-light-gradient) 100%);*/
  background: var(--lightgreen);
  overflow-y: auto;
}

.tabGradient {
  background: rgb(202, 252, 214);
  background: linear-gradient(0deg, var(--green-light-gradient) 0%, var(--white) 40%, var(--white) 60%, var(--green-light-gradient) 100%);
}

.tabTopStripeContainer {
  margin: 15px 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tabTopStripe {
  background-color: var(--primary);
  width: 50px;
  height: 5px;
  border-radius: 20px;
}