
.toastBg {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.toastContainer {
  min-width: 300px;
  background-color: var(--lightgrey);
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: none;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  min-height: auto;
  border-radius: 15px;
}

.toastIcon {
}

.toastText, .toastMessage {
  color: var(--text);
  font-weight: normal;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  text-wrap: nowrap;
  font-size: 20px;
}