.container {
  padding: 10px;
  display: flex;
  justify-content: center;
  width: calc(100% - 20px);
  background-color: #FFF;
  -webkit-box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.tabs {
  width: 600px;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
}

.tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  font-size: 10px;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-weight: normal;
  max-width: 20vw;
  overflow: hidden;
}

.titleActive {
  color: var(--black300);
  margin-top: 2px;
}

.titleInactive {
  color: var(--grey100);
  margin-top: 2px;
}

.list .a {
  text-decoration: none;
  color: var(--color-primary-400);
}

.list a:hover,
.list a.active {
  color: var(--color-primary-800);
  text-decoration: underline;
}

.iconActive {
  width: 50px;
  /*https://codepen.io/sosuke/pen/Pjoqqp*/
  filter: invert(19%) sepia(6%) saturate(205%) hue-rotate(324deg) brightness(92%) contrast(93%)
}

.iconInactive {
  width: 50px;
  filter: invert(61%) sepia(78%) saturate(14%) hue-rotate(319deg) brightness(83%) contrast(105%);
}

.tabBadge {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  margin-left: 21px;
  margin-top: -26px;
  font-size: 10px;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: var(--black);
  background-color: var(--primary);
}

.tabBadgeInactive {
  /*background-color: var(--grey100);*/
}

.tabBadgeActive {
  /*background-color: var(--green);*/
}

@media (max-width: 768px) {

  .iconActive,
  .iconInactive {
    width: 30px;
  }

  .tab {
    font-size: 10px;
  }

}