.checklistSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  border-radius: 12px;
  overflow: hidden;
}

.checklistItemList {
  overflow: auto;
  flex:1;
  margin-left: 8px;
}

/*.checklistItemsContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  position: relative;
}*/
.checklistItemsContainer {
  width: 100%;
  overflow: auto;
  margin: auto;
  flex: 1;
}

.checklistItemsContainerOpen {
  background-color: var(--white);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.checklistHeaderItemsLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--primary);
  overflow: hidden;
  cursor: pointer;
  min-height:72px;
}
.checklistChildTitleContainer{
  display: flex;
  flex-direction: row;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}
.checklistChildTitle {
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
font-size: 20px;
font-weight: normal;
}

.checklistHeaderItemsLeft h2{
  color: var(--text);
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
  margin: 0;
  display: flex;
  flex: 1;
}