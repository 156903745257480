.formFlexRow {
  display: flex;
  flex-direction: row;
}

.formInput {
  margin-bottom: 12px;
  border: solid 1px var(--lightgrey300);
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.formInput::placeholder {
  color: #000000;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  background-image: url(../../assets/icons/calendar.svg);
  background-repeat: no-repeat;
  background-position: 4px 50%;
  background-size: contain;
  width: 30px;
  height: 30px;
}

input[type="date"] {
  padding: 8.2px 15px 8.2px 20px;
}

input[type="date"]::before {
  content: attr(data-placeholder);
  width: 100%;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  display: none
}

.submitButton {
  width: 420px;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.settings_buttonText {
  color: var(--text);
  margin: 0 6px 0 22px;
  flex: 1;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
  text-align: left;
}

.settings_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 7px 0px 9px 10px;
  flex: 1
}

.settings_buttonIconHolder {
  width: 26px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.settings_buttonIcon {
  height: 26px;
}

.settings_buttonArrow {
  width: 10px;
  padding-right: 10px;
}

.addEventContainer {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.addEventButtonLabel {
  font-size: 20px;
  color: var(--text);
  font-weight: normal;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;  
}

.addEventButtonIcon {
  color: var(--primary);
}

.editMemberContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editMemberForm {
  width: 80%;
  max-width: 600px;
}

.avatarContainer {
  flex: 1 0 25%;
  margin: 10px 5px;
  cursor: pointer;
}

.avatarsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 300px;
}

.editorContainer {
  margin: 20px 0;
}

.editMemberForm .formInput::placeholder {
  color: #00000063;
}

.editItemForm {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
  width: calc(100% - 30px);
}

.editItemName {
  background-color: var(--white);
  border-radius: 20px;
  padding: 20px;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
font-weight: normal;
  margin: 0 5px;
  border: none;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  font-size: 14px;
  text-align: left;
  text-wrap: nowrap;
}

.editItemEqual {
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
font-weight: normal;
}

.editItemAssign {
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
font-weight: normal;
  width: auto;
  margin: 0 5px;
  flex: 3;
  max-width: 30%;

  background-color: var(--white);
  border-radius: 20px;
  padding: 20px;
  border: none;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  font-size: 14px;

}

.editItemMethod {
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
font-weight: normal;
  width: auto;
  margin: 0 5px;
  flex: 2;
  background-color: var(--white);
  border-radius: 20px;
  padding: 20px;
  border: none;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  font-size: 14px;
  max-width: 30%;
}

.editItemSelectPlaceholder {
  color: var(--grey);
}

.editItemProgressIcon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.editItemDelete {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px
}

.editItemNotes {
  padding: 20px;
  background: none;
  background-color: var(--white);
  border: none;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  width: calc(90% - 40px);
  font-size: 14px;
  margin: 20px 0;
  /* flex-grow: 1; */
  height: 100px;
  min-height: 100px;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  border-radius: 20px;
}


.icon_share {
  width: 20px;
}

@media (max-width: 500px) {
  .editItemForm {
    width: 100%;
  }

  .editItemName {
    display: none;
    background-color: inherit;
    box-shadow: none;
    padding: 0;
  }

  .editItemEqual {
    display: none;
  }

  .image-dropdown {
    width: 35%;
  }

}

.formImg {
  margin-bottom: -40px;
  margin-left: auto;
  margin-right: 15px;
}

.formEdit {
  display: block;
}