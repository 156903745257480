.checklistAddGroup {
  display: flex;
  flex-direction: row;
}

.selectInput {
  margin: 20px 0;
  border: none;
  border: solid 1px var(--primary);
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
}

.selectInput::placeholder {
  color: #000000;
}

.checklistAddLabel {
  background-color: var(--primary);
  padding: 0 35px 0 20px;
  font-size: 16px;
  color: var(--text);
  border: none;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  min-height: auto;
  border-radius: 15px;
  width: 100%;
  height: 40px;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  text-wrap: nowrap;
  line-height: 16px;
  text-align: left;
  align-items: center;
  display: flex;
  cursor: pointer;
}