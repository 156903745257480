.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img {
  background-color: #fff;
  border-radius: 50%;
  border: solid 2px var(--lightgrey300);
}

.imgSmall {
  width: 32px;
  height: 32px;
}

.imgMedium {
  width: 30px;
  height: 30px;
}

.imgLarge {
  width: 40px;
  height: 40px;
}

.imgHuge {
  width: 60px;
  height: 60px;
}

.selectedImg {
  border-color: var(--primary);
  position: relative;
}

.text {
  text-align: center;
  color: var(--black);
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
  margin-top: 5px;
  text-overflow: ellipsis;
  line-height: 10px;
  margin-left: -10px;
}