.checklistAddGroup {
  display: flex;
  justify-content: center;
  padding: 0px 20px;
}

.heading {
  color: var(--black) !important;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 20px;
  margin: 0;
  padding: 5px;
  cursor: pointer;
  text-align: center !important;
}

.editItem {
  color: var(--black) !important;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 34px;
  margin: 0;
  padding: 5px;
  cursor: pointer;
  text-align: center !important;
  min-width: 200px;
}