.festivalsContainer {
  margin-top: 26px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.containerContentTop {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.img {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}


.content {
  padding: 10px;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
}

.contentTitle {
  flex: 1;
  text-align: center;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
  padding: 20px 10px;
}

.contentText {
  flex-grow: 1;
  width: 80%;
  max-width: 600px;
  min-width: 300px;
}

.contentText p {
  text-align: center;
  font-weight: normal;
}

.cursorPointer {
  cursor: pointer;
}

a {
  color: var(--primaryDark);
}

@media (max-width: 400px) {

  .festivalsContainer {
    flex-direction: column;
    align-items: center;
  }

}

.cancelEventButton {
  background: #000;
}

.addEventContainer {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.addEventButtonLabel {
  font-size: 20px;
  color: var(--text);
  font-weight: normal;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
}

.addEventButtonIcon {
  color: var(--primary);
}