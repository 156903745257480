.numberAdd,
.numberRemove {
  width: 32px;
  height: 34px;
  background-color: var(--lightgreen100);
  border-radius: 50%;
  font-size: 23px;
  color: var(--black200);
  box-sizing: border-box;
}

.numberAdd {
  margin-right: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.numberRemove {
  margin-left: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.hidden {
  display: none !important;
}