.checklistHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #FFF;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.checklistHeaderGroupLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*justify-content: flex-end;*/
  max-width: 210px;
  width: 65%;
  /*background-color: var(--lightgrey100);*/
  border-radius: 10px;
  overflow: hidden;
}

.checklistHeaderNameInput {
  /*background-color: var(--lightgrey100);*/
  padding: 0 0 0 7px;
  color: var(--text);
  border: none;
  min-height: auto;
  border-radius: 15px;
  width: 100%;
  height: 40px;
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
  text-wrap: nowrap;
  line-height: 16px;
  text-align: left;
  align-items: center;
  display: flex;
  cursor: pointer;
}



.checklistHeaderGroupRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}

.checklistHeaderText {
  font-family: 'MadeTommy', Arial, Helvetica, sans-serif;
font-size: 20px;
font-weight: normal;
  color: var(--black100);
}

.checklistHeaderIcon {
  color: var(--primary);
}

@media (max-width: 400px) {
  .checklistHeaderContainer {
    width: calc(100% + 10px);
  }

}